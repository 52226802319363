import { CanActivateFn, Router } from '@angular/router';
import { inject } from "@angular/core";
import { JwtService } from "../services/jwt.service";

export const NoAuthGuard: CanActivateFn = (route, state) => {
  const jwtService = inject(JwtService);
  const router = inject(Router);

  if (!jwtService.getToken()) {
    return true;
  }

  router.navigateByUrl('/dashboard');
  return false;
};
