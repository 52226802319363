import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable, of, switchMap, take, tap } from "rxjs";
import { select, Store } from "@ngrx/store";
import { ExamState } from "../../ngrx/exam/exam.reducer";
import { selectExam } from "../../ngrx/exam/exam.selectors";
import { loadExams } from "../../ngrx/exam/exam.actions";
import { IExam } from "../../models/exam/exam.model";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ExamService {
  private readonly apiPrefix = '/admin/exams';

  constructor(
    private api: ApiService,
    private store: Store<ExamState>
  ) {
  }

  getExams(): Observable<any> {

    return this.store.pipe(
      select(selectExam),
      take(1),
      switchMap((exams) => {
        if (exams.length > 0) {
          return of({data: exams});
        } else {
          return this.api.get(this.apiPrefix).pipe(
            tap((examsFromApi) => {
              const data = examsFromApi.data as IExam[];
              this.store.dispatch(loadExams(data));
            }),
            catchError(error => {
              // Handle any errors appropriately
              console.error('Error fetching exams from API', error);
              return of([]);
            })
          );
        }
      })
    );
  }
}
