import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class MetaTagService {
  private readonly appName: string;

  constructor(
    private titleService: Title,
    private translate: TranslateService,
  ) {
    this.appName = this.translate.instant('titles.app');
  }

  setTitle(title: string) {
    // const t = this.translate.instant(title);
    // this.titleService.setTitle(`${t}`);
  }


}
