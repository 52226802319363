<div id="layout-wrapper">

  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar></app-sidebar>

  <div class="main-content">
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>
