import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class UserService {
  private apiPrefix = '/admin/users';

  constructor(
    private api: ApiService,
  ) {
  }

  getUsers(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getUser(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  updateUser(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deleteUser(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  createUser(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  createPhotoUpload(userId: number, mimeType: string) {
    return this.api.post(`${this.apiPrefix}/${userId}/photo/upload`, {mimeType});
  }

  savePhoto(userId: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${userId}/photo/upload/${requestId}`, {});
  }

  changePassword(userId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${userId}/password`, data);
  }

  changeEmail(userId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${userId}/email`, data);
  }

  removeProfilePhoto(userId: number) {
    return this.api.delete(`${this.apiPrefix}/${userId}/photo`);
  }

  changeUserPlan(userId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${userId}/premium-plan`, data);
  }

  removeUserPlan(userId: number) {
    return this.api.delete(`${this.apiPrefix}/${userId}/premium-plan`);
  }

  getUserPlan(userId: number) {
    return this.api.get(`${this.apiPrefix}/${userId}/premium-plan`);
  }

  getUserRegisterReports(qs = '') {
    return this.api.get(`${this.apiPrefix}/register-reports${qs}`);
  }

  getUserLevelReports(qs = '') {
    return this.api.get(`${this.apiPrefix}/user-level-counts${qs}`);
  }
}
