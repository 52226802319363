import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ExamMentorService {
  private apiPrefix = '/admin/exam-mentors';

  constructor(
    private api: ApiService,
  ) {
  }

  createExamMentor(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updateExamMentor(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  getMentorList(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  deleteMentor(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getDetail(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  createImage(id: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${id}/image`, data);
  }

  saveImage(id: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${id}/image/${requestId}`, {});
  }

  getMentorVideos(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/videos`);
  }

  updateMentorVideos(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}/videos`, data);
  }

  updateAsActive(id: number){
    return this.api.patch(`${this.apiPrefix}/${id}/active`, {});
  }

  updateAsPassive(id: number){
    return this.api.patch(`${this.apiPrefix}/${id}/passive`, {});
  }

  updateMonthlyScores(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}/average-scores`, data);
  }
}
