import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class VideoSourceService {
  private readonly apiPrefix = '/admin/video-sources';

  constructor(
    private api: ApiService,
  ) {
  }

  getVideoSources(search?: string): Observable<any> {
    let url = `${this.apiPrefix}`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.api.get(url);
  }

  getSourceDetail(providerId: string) {
    return this.api.get(`${this.apiPrefix}/${providerId}`);
  }

}
