import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class UserDailyWorkReportService {
  private apiPrefix = '/admin/daily-work-report';

  constructor(
    private api: ApiService,
  ) {
  }

  getUserDailyWorks(userId: number, body: object) {
    return this.api.post(`${this.apiPrefix}/${userId}`, body);
  }
}
