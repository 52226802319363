import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class LiveStreamPollService {
  private apiPrefix = '/admin/live-streams/'

  constructor(
    private api: ApiService,
  ) {
  }

  getStreamPolls(streamId: number) {
    return this.api.get(`${this.apiPrefix}${streamId}/polls`)
  }

  createPoll(streamId: number, data: object) {
    return this.api.post(`${this.apiPrefix}${streamId}/polls`, data);
  }

  endPoll(streamId: number, pollId: number) {
    return this.api.patch(`${this.apiPrefix}${streamId}/polls/${pollId}/end`, {});
  }
}
