import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ApplicationSettingService {
  private readonly apiPrefix = '/admin/application-settings'

  constructor(
    private api: ApiService,
  ) {
  }

  getSettings() {
    return this.api.get(`${this.apiPrefix}`);
  }

  updateSettings(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  createFileUpload(settingId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${settingId}/upload`, data);
  }

  saveFileUpload(settingId: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${settingId}/upload/${requestId}`, {});
  }

  createContentMedia(mimeType: string) {
    return this.api.post(`${this.apiPrefix}/content-media`, {mimeType});
  }

  saveContentMedia(requestId: string) {
    return this.api.patch(`${this.apiPrefix}/content-media/${requestId}`, {});
  }
}
