import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, Subject, takeUntil } from "rxjs";
import { filter, map } from "rxjs/operators";

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnDestroy, OnInit {
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  @Input() showLabel = true;
  @Input() labelText = 'table.search';
  @Input() placeholderText = 'table.search';

  @Output() onSearchChange = new EventEmitter<string>();
  destroyed$ = new Subject();

  ngOnInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => {
        if (res === '') {
          return true
        } else return res.length > 2;
      }),
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    ).subscribe((text: string) => {
      this.onSearchChange.emit(text);
    });
  }


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
