import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class ProfileService {
  private readonly apiPrefix = '/admin/profile';

  constructor(
    private api: ApiService
  ) {
  }

  updateProfile(data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}`, data);
  }
}
