<input
  type="text" autocomplete="off"
  [placeholder]="placeholder"
  class="form-control"
  [value]="value"
  (bsValueChange)="onChange($event)"
  [bsConfig]="datePickerConfig"
  [bsValue]="value "
  #allocationDatepicker="bsDatepicker"
  bsDatepicker>
