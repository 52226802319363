import {Injectable} from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Router} from '@angular/router';
import {Ability} from '@casl/ability';

@Injectable()
export class CanActivateForComponents implements CanActivate {

  constructor(private router: Router,
              private ability: Ability) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    const components = route.data.components as Array<string>;
    let canActive = false;

    if (components){
      components.forEach(c=>{
        if (this.ability.can('manage',c) || this.ability.can('view',c)){
          canActive = true;
        }
      });

    }

    if (!canActive){
      this.router.navigate(['/error/401']);
    }

    return canActive;
  }
}
