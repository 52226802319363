import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class CampaignService {
  private apiPrefix = '/admin/campaigns';

  constructor(
    private api: ApiService
  ) {
  }

  create(data) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  update(id: number, data) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  getList(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  remove(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  publish(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/publish`, {});
  }

  unpublish(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}/publish`);
  }

  createImage(id: number, data) {
    return this.api.post(`${this.apiPrefix}/${id}/image`, data);
  }

  saveImage(id: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${id}/image/${requestId}`, {});
  }
}
