import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { JwtService } from "../jwt.service";
import { AppAbilityService } from "./app-ability.service";
import { Store } from "@ngrx/store";
import { State } from "../../../reducers";
import { loadCurrentAdmin } from "../../ngrx/admin/admin.actions";

@Injectable()
export class AuthService {
  private readonly apiPrefix = '/admin/authentication';

  constructor(
    private api: ApiService,
    private jwtService: JwtService,
    private appAbility: AppAbilityService,
    private store: Store<State>,
  ) {
  }

  login(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/login`, data)
      .pipe(map(r => {
        this.jwtService.saveToken(r.data.accessToken);
        this.appAbility.updateAbilities(r.data.adminUser.role, r.data.adminUser);
        return r;
      }));
  }

  me(): Observable<any> {
    return this.api.get(`${this.apiPrefix}/me`);
  }

  updateRoles() {
    if (this.jwtService.getToken()) {
      return this.me().toPromise().then(r => {
        this.store.dispatch(loadCurrentAdmin(r.data));
        this.appAbility.updateAbilities(r.data.role, r.data);
      }).catch(e => {
        this.logout();
      });

    } else {
      return Promise.resolve().then(r => this.logout());
    }
  }

  changePassword(data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/password`, data);
  }

  changeMailRequest(data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/email`, data);
  }

  changeMail(requestId: string, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/email/${requestId}`, data);
  }

  forgotPassword(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/forgot-password`, data);
  }

  validateForgotPassword(requestId: string, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/forgot-password/${requestId}`, data)
  }

  logout() {
    this.jwtService.destroyToken();
  }

}
