import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize, Observable, Subject, takeUntil } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../core/services/auth/auth.service";
import { Store } from "@ngrx/store";
import { AdminState } from "../../../core/ngrx/admin/admin.reducer";
import { IAdmin } from "../../../core/models/admin/admin.model";
import { selectAdmin } from "../../../core/ngrx/admin/admin.selectors";
import { TranslateService } from "@ngx-translate/core";
import { NotEqualValidator } from "../../../core/validators/not-equal.validator";
import { ToastrService } from "ngx-toastr";
import { loadCurrentAdmin } from "../../../core/ngrx/admin/admin.actions";
import { IsEmail } from "../../../core/validators/is-email.validator";

@Component({
  selector: 'app-change-mail-modal',
  templateUrl: './change-mail-modal.component.html',
  styleUrls: ['./change-mail-modal.component.scss']
})
export class ChangeMailModalComponent implements OnInit, OnDestroy {
  admin$: Observable<IAdmin>;
  currentAdmin: IAdmin;
  activeStep: 'newMail' | 'validation' = 'newMail';
  newMailForm: FormGroup;
  validationForm: FormGroup;
  isLoading = false;
  isSubmitted = false;
  requestId?: string;
  hasError = false;
  destroyed$ = new Subject();

  constructor(
    public modal: BsModalRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private toastService: ToastrService,
    private store: Store<AdminState>,
  ) {
    this.admin$ = store.select(selectAdmin);
  }

  ngOnInit(): void {
    this.admin$.pipe(takeUntil(this.destroyed$))
      .subscribe(adminData => {
        this.currentAdmin = adminData;
        this.setForms();
      });
  }

  setForms() {
    this.newMailForm = this.formBuilder.group({
      email: ['', [Validators.required, IsEmail(), NotEqualValidator(this.currentAdmin.email)]],
      lang: [this.translate.currentLang]
    });

    this.validationForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(6)]],
    });
  }

  get newMailF() {
    return this.newMailForm.controls;
  }

  get validationF() {
    return this.validationForm.controls;
  }

  onSubmitNewMail(): void {
    this.isSubmitted = true;
    if (this.newMailForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.authService.changeMailRequest(this.newMailForm.value)
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.requestId = r.data.requestId;
        this.activeStep = 'validation';
        this.isSubmitted = false;
      });
  }

  onSubmitValidation(): void {
    this.isSubmitted = true;
    this.hasError = false;
    if (this.validationForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.authService.changeMail(this.requestId, this.validationForm.value)
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.toastService.success(this.translate.instant('toast_messages.success_updated'));
        const admin: IAdmin = {...this.currentAdmin, email: r.data.email};
        this.store.dispatch(loadCurrentAdmin(admin));
        this.modal.hide();
      }, err => {
        this.hasError = true;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
