import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class UserPaymentService {
  private apiPrefix = '/admin/user-payments';

  constructor(
    private api: ApiService
  ) {
  }

  getUserPayments(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  updateAsRefund(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getPaymentReports(qs = '') {
    return this.api.get(`${this.apiPrefix}/reports${qs}`);
  }
}
