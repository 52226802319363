import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class VideoService {
  private readonly apiPrefix = '/admin/videos';

  constructor(
    private api: ApiService
  ) {
  }

  getVideos(qs: string = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  createVideo(data): Observable<any> {
    return this.api.post(this.apiPrefix, data);
  }

  updateVideo(id: number, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deleteVideo(id: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  publishVideo(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/publish`, {});
  }

  publishVideoWithDate(id: number, publishDate: Date) {
    return this.api.patch(`${this.apiPrefix}/${id}/publish-date`, {
      publishDate: publishDate
    });
  }

  unpublishVideo(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/unpublish`, {});
  }

  uploadVideoRequest(id: number, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/${id}/image`, data);
  }

  saveVideoImage(id: number, requestId: string): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}/image/${requestId}`, {});
  }

  getVideoPlaylists(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/playlists`);
  }

  updateVideoPlaylists(id: number, data: object) {
    return this.api.put(`${this.apiPrefix}/${id}/playlists`, data);
  }
}
