import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { ModalModule } from "ngx-bootstrap/modal";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { ChangeMailModalComponent } from './change-mail-modal/change-mail-modal.component';
import { MyProfileModalComponent } from './my-profile-modal/my-profile-modal.component';


@NgModule({
  declarations: [
    ChangePasswordModalComponent,
    ChangeMailModalComponent,
    MyProfileModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule,
    ReactiveFormsModule,
    AlertModule,
  ],
  exports: [
    ChangePasswordModalComponent,
  ]
})
export class AccountModalsModule {
}
