import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class RoleService {
  private readonly apiPrefix = '/admin/roles';

  constructor(
    private api: ApiService,
  ) {
  }

  getRoles(): Observable<any> {
    return this.api.get(`${this.apiPrefix}`);
  }

  getRoleWithId(id: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  deleteRole(id: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  createRole(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updateRole(id: number, data: object): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }
}
