import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class LocationService {
  private apiPrefix = '/public/locations'

  constructor(
    private api: ApiService,
  ) {
  }

  getCities() {
    return this.api.get(`${this.apiPrefix}/cities`);
  }

  getDistricts(cityId: number) {
    return this.api.get(`${this.apiPrefix}/cities/${cityId}/districts`);
  }
}
