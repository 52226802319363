<div class="modal-header">
  <h5 class="modal-title mt-0">{{'modals.change_mail.title'|translate}}</h5>
  <button type="button" class="btn-close" (click)="modal.hide()">
    <span aria-hidden="true"></span>
  </button>
</div>

<div class="modal-body">
  <div class="row" *ngIf="activeStep == 'newMail'">

    <div class="col-12">
      <small>{{'modals.change_mail.validation_desc'|translate}}</small>
    </div>

    <form [formGroup]="newMailForm" (submit)="onSubmitNewMail()" autocomplete="off">

      <div class="col-12 mt-2">
        <label for="email" class="form-label">{{'labels.new_mail_address'|translate}}</label>
        <input formControlName="email" id="email" class="form-control" autocomplete="off" type="text"
               [placeholder]="'labels.new_mail_address'|translate">
        <div *ngIf="isSubmitted && newMailF.email.errors" class="invalid-feedback">
          <span *ngIf="newMailF.email.errors.required">{{'validation.field_required'|translate}}</span>
          <span *ngIf="newMailF.email.errors.email">{{'validation.valid_mail'|translate}}</span>
          <span
            *ngIf="newMailF.email.errors.notEqual">{{'validation.new_mail_cannot_equal_current_mail'|translate}}</span>
        </div>
      </div>

      <div class="col-12 mt-2 text-center">
        <button [disabled]="isLoading" class="btn btn-primary" type="submit">
          <i *ngIf="isLoading" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          {{'buttons.send_validation_code'|translate}}
        </button>
      </div>

    </form>
  </div>

  <div class="row" *ngIf="activeStep == 'validation'">
    <div class="col-12">
      <small>{{'modals.change_mail.enter_code_desc'|translate: {email: currentAdmin.email} }}</small>
    </div>
    <alert type="danger" *ngIf="hasError" [dismissible]="false">{{ 'validation.cannot_validate_code'|translate }}</alert>

    <form [formGroup]="validationForm" (submit)="onSubmitValidation()" autocomplete="off">

      <div class="col-12 mt-2">
        <label for="code" class="form-label">{{'labels.validation_code'|translate}}</label>
        <input formControlName="code" id="code" class="form-control" autocomplete="off" type="text"
               [placeholder]="'labels.validation_code'|translate">
        <div *ngIf="isSubmitted && validationF.code.errors" class="invalid-feedback">
          <span *ngIf="validationF.code.errors.required">{{'validation.field_required'|translate}}</span>
          <span *ngIf="validationF.code.errors.minlength">
            {{'validation.min_length'|translate: {n: validationF.code.errors.minlength.requiredLength} }}
          </span>
          <span *ngIf="validationF.code.errors.maxlength">
            {{'validation.max_length'|translate: {n: validationF.code.errors.maxlength.requiredLength} }}
          </span>
        </div>
      </div>

      <div class="col-12 mt-2 text-center">
        <button [disabled]="isLoading" class="btn btn-primary" type="submit">
          <i *ngIf="isLoading" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          {{'buttons.validate'|translate}}
        </button>
      </div>

    </form>
  </div>

</div>
