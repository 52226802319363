import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject, Observable } from "rxjs";
import { JwtService } from "../jwt.service";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class LiveStreamWsService extends Socket {

  constructor(
    private jwtService: JwtService,
  ) {
    const token = jwtService.getToken();
    super({
      url: environment.wsUrl,
      options: {
        autoConnect: false,
        reconnection: false,
        path: '/live-stream/socket.io',
        extraHeaders: {
          'admin-authorization': token,
        }
      }
    })
  }

  connectTo() {
    this.connect();
  }

  joinToChat(streamId: number) {
    return this.emit('admin/join-stream', {
      liveStreamId: streamId
    });
  }

  listenMessages(): Observable<any> {
    return this.fromEvent('chat');
  }

  showOverlayMessage(streamId: number, messageId: number, delay: number) {
    return this.emit('admin/show-message', {
      liveStreamId: streamId,
      messageId,
      delay,
    });
  }

  sendTestMessage(streamId: number, message: string) {
    return this.emit('admin/show-test-message', {
      liveStreamId: streamId,
      message
    });
  }

  deleteMessage(streamId: number, messageId: number) {
    return this.emit('admin/remove-message', {
      liveStreamId: streamId,
      messageId,
    });
  }

  clearOverlayMessage(streamId: number) {
    return this.emit('admin/clear-message', {
      liveStreamId: streamId,
    });
  }

}
