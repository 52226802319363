import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() placeholder = '';
  datePickerConfig = {
    isAnimated: true,
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD',
    keepDatepickerOpened: true,
    withTimepicker: false,
    adaptivePosition: true,
    minDate: undefined,
    maxDate: undefined,
  };
  value: Date | undefined | null;

  constructor(
    private localeService: BsLocaleService,
    private translate: TranslateService,
  ) {

  }

  ngOnInit() {
    this.datePickerConfig.minDate = this.minDate;
    this.datePickerConfig.maxDate = this.maxDate;
    this.localeService.use(this.translate.currentLang);
  }

  onChange = (value: Date | undefined | null) => {
  };

  onTouched = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
