import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../core/services/auth/auth.service";
import { AdminState } from "../../core/ngrx/admin/admin.reducer";
import { loadCurrentAdmin } from "../../core/ngrx/admin/admin.actions";
import { BsModalService } from "ngx-bootstrap/modal";
import {
  ChangePasswordModalComponent
} from "../../shared/account-modals/change-password-modal/change-password-modal.component";
import { ChangeMailModalComponent } from "../../shared/account-modals/change-mail-modal/change-mail-modal.component";
import { MyProfileModalComponent } from "../../shared/account-modals/my-profile-modal/my-profile-modal.component";
import { Observable, Subject, takeUntil } from "rxjs";
import { IAdmin } from "../../core/models/admin/admin.model";
import { selectAdmin } from "../../core/ngrx/admin/admin.selectors";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  admin$: Observable<IAdmin>;
  currentAdmin: IAdmin;
  destroyed$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthService,
    private store: Store<AdminState>,
    private modalService: BsModalService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
  ) {
    this.admin$ = store.select(selectAdmin);
  }

  listLang = [
    {text: 'Türkçe', flag: 'assets/images/flags/tr.jpg', lang: 'tr'},
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {

    this.admin$.pipe(takeUntil(this.destroyed$))
      .subscribe(adminData => {
        this.currentAdmin = adminData;
      });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.authService.logout();
    this.store.dispatch(loadCurrentAdmin(null));
    this.router.navigateByUrl('/auth/login');
  }

  openChangePassModal() {
    const modalRef = this.modalService.show(ChangePasswordModalComponent, {
      keyboard: false,
      class: 'modal-dialog-centered modal-lg',
    });
  }

  openEditProfileModal() {
    const modalRef = this.modalService.show(MyProfileModalComponent, {
      keyboard: false,
      class: 'modal-dialog-centered modal-lg',
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
