import { AbstractControl } from "@angular/forms";

export function IsEmail() {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.parent) {
      return null;
    }

    if (!control.value){
      return { 'email': true };
    }

    const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
    return matches ? null : { 'email': true };
  }
}
