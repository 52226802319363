import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtService } from "../services/jwt.service";


@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
    private router: Router,
    private jwtService: JwtService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.jwtService.getToken()) {
      return true;
    }
    this.router.navigateByUrl('/auth/login');
    return false;
  }
}
