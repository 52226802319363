import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from "../services/jwt.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private jwtService: JwtService,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    const token = this.jwtService.getToken();

    if (token && (!request.url.includes('amazonaws'))) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    // Delete content-type for it to be set automatically as multipart/form-data with boundary
    isPlatformBrowser(this.platform);
    {
      if (request.body) {
        delete headersConfig['Content-Type'];
      }
    }


    const req = request.clone({setHeaders: headersConfig});
    return next.handle(req);
  }
}
