import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from "./core/core.module";
import { AbilityModule } from "@casl/angular";
import { Ability, PureAbility } from "@casl/ability";
import { StoreModule } from '@ngrx/store';
import { adminFeatureKey, adminReducer } from "./core/ngrx/admin/admin.reducer";
import { metaReducers, reducers } from "./reducers";
import { AuthService } from "./core/services/auth/auth.service";
import { ModalModule } from "ngx-bootstrap/modal";
import { CanActivateForComponents } from "./core/guards/can-activate-for-components.guard";
import { AppAbility } from "./core/services/auth/app-ability.service";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { defineLocale, trLocale } from "ngx-bootstrap/chronos";
import { BsDatepickerModule, BsLocaleService } from "ngx-bootstrap/datepicker";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { SocketIoModule } from "ngx-socket-io";
import { provideNgxMask } from "ngx-mask";
import { examFeatureKey, examReducer } from "./core/ngrx/exam/exam.reducer";
import localeTr from '@angular/common/locales/tr';

defineLocale('tr', trLocale);
registerLocaleData(localeTr);

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AbilityModule,
    CoreModule,
    SocketIoModule,
    //region ngrx
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreModule.forFeature(adminFeatureKey, adminReducer),
    StoreModule.forFeature(examFeatureKey, examReducer),
    //endregion
    LayoutsModule,
    AppRoutingModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ScrollToModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1500,
      progressBar: true,
    }),
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'tr-TR',
    },
    {
      provide: AppAbility,
      useValue: new AppAbility()
    },
    {
      provide: PureAbility,
      useExisting: AppAbility
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => {
        return () => auth.updateRoles();
      },
      deps: [AuthService],
      multi: true,
    },
    provideNgxMask(),
    CanActivateForComponents,
    DatePipe,
  ],
})
export class AppModule {
}
