import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class SchoolService {
  private apiPrefix = '/admin/schools';

  constructor(
    private api: ApiService
  ) {
  }

  createSchool(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updateSchool(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  getSchools(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getCitySchools(cityId: number, qs = '') {
    return this.api.get(`${this.apiPrefix}/city/${cityId}${qs}`);
  }

  deleteSchool(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getDistrictSchools(cityId: number, districtId: number, qs = '') {
    return this.api.get(`${this.apiPrefix}/city/${cityId}/district/${districtId}${qs}`);
  }
}
