import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";

@Injectable()
export class PermissionService {
  private readonly apiPrefix = '/admin/permissions';

  constructor(
    private api: ApiService,
  ) {
  }

  getPermissions() {
    return this.api.get(this.apiPrefix);
  }
}
