import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ProfanityFilterService {
  private apiPrefix = '/admin/profanity';

  constructor(
    private api: ApiService
  ) {
  }

  get(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  removeAll() {
    return this.api.delete(`${this.apiPrefix}/all`);
  }

  remove(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  addWords(words: string[]) {
    return this.api.post(`${this.apiPrefix}`, {words: words});
  }

  /**
   * Get bloom filter data [Development purpose]
   */
  getFilterData() {
    return this.api.get(`${this.apiPrefix}/filter`);
  }

  /**
   * Re-calculate bloom filter hash [Development purpose]
   */
  reCalculateHash() {
    return this.api.post(`${this.apiPrefix}/calculate`, {});
  }

  /**
   * Check given message is profane or not [Development purpose]
   * @param message
   */
  checkMessageIsProfane(message: string) {
    return this.api.post(`${this.apiPrefix}/check-message`, {message: message});
  }
}
