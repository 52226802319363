import { AbstractControl } from "@angular/forms";

export function NotEqualValidator(notEqualVal: string) {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.parent) {
      return null; // Control is not yet associated with a parent.
    }

    if (notEqualVal != control.value) {
      return null;
    }

    return {
      'notEqual': true
    };
  }
}
