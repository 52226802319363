import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class AdminService {
  private readonly apiPrefix = '/admin/admin-users';

  constructor(
    private api: ApiService,
  ) {
  }

  getAdmins(qs: string): Observable<any> {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  deleteAdmin(id: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  createAdmin(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updateAdmin(id: number, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }
}
