import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BsDatepickerDirective, BsLocaleService } from "ngx-bootstrap/datepicker";
import { TimepickerState } from "ngx-bootstrap/timepicker/reducer/timepicker.reducer";
import { take } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    }
  ],
})
export class DatetimePickerComponent implements ControlValueAccessor, OnInit {
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() placeholder = '';
  datePickerConfig = {
    isAnimated: true,
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
    keepDatepickerOpened: true,
    withTimepicker: true,
    adaptivePosition: true,
    minDate: undefined,
    maxDate: undefined,
  };
  value: Date | undefined | null;

  constructor(
    private localeService: BsLocaleService,
    private translate: TranslateService,
  ) {

  }

  ngOnInit() {
    this.datePickerConfig.minDate = this.minDate;
    this.datePickerConfig.maxDate = this.maxDate;
    this.localeService.use(this.translate.currentLang);
  }

  onChange = (value: Date | undefined | null) => {
  };

  onTouched = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  // TODO fix me properly!
  initDatepicker(dp: BsDatepickerDirective): void {
    // defer execution until reference to datepicker container is initialized
    setTimeout(() => {
      const timepicker = (dp as any)._datepickerRef.instance.startTimepicker!;

      // override rendering method
      const originalMethod = timepicker._renderTime as (value?: string | Date) => void;
      const newMethod = (value?: string | Date) => {
        // set the "showMeridian" property to false and call original method
        timepicker.showMeridian = false;
        originalMethod.call(timepicker, value);
        // reset back to true to avoid an infinite loop
        timepicker.showMeridian = true;
      };
      timepicker._renderTime = newMethod;

      // re-render time in case it's initialized with a 00 or 12+ hour
      timepicker._store.select((state: TimepickerState) => state.value)
        .pipe(take(1))
        .subscribe((value: Date | undefined) => {
          newMethod(value);
          timepicker._cd.markForCheck();
        });
    });
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
