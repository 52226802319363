import { IExam } from "../../models/exam/exam.model";
import { Action, createReducer, on } from "@ngrx/store";
import * as ExamActions from './exam.actions';

export const examFeatureKey = 'EXAM_STATE_KEY';

export interface ExamState {
  exams: IExam[]
}

export const initialState: ExamState = {
  exams: [],
};

export const reducer = createReducer(
  initialState,
  on(ExamActions.loadExams,
    (state: ExamState, {exams}) => ({
      ...state,
      exams
    })
  )
);

export function examReducer(state: ExamState | undefined, action: Action): any {
  return reducer(state, action);
}
