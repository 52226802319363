import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class NotificationService {
  private apiPrefix = '/admin/notifications';

  constructor(
    private api: ApiService
  ) {
  }

  getTypes() {
    return this.api.get(`${this.apiPrefix}/types`);
  }

  sendNotification(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  getNotifications(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  cancelNotification(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }
}
