import { ApplicationSettingService } from "../../../core/services/application-setting/application-setting.service";
import { ApiService } from "../../../core/services/api.service";
import { ToastrService } from "ngx-toastr";

export default class Adapter {
  loader;
  reader;
  config;
  settingService: ApplicationSettingService;
  apiService: ApiService;
  toastService: ToastrService;

  constructor(
    loader: any,
    config: any,
    settingService: ApplicationSettingService,
    apiService: ApiService,
    toastService: ToastrService,
  ) {
    this.loader = loader;
    this.config = config;
    this.settingService = settingService;
    this.apiService = apiService;
    this.toastService = toastService;
  }


  public async upload(): Promise<any> {
    const value = await this.loader.file;
    return this.read(value);
  }

  read(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const uploadFile = file as File;
          const uploadRequest = await this.settingService.createContentMedia(uploadFile.type).toPromise();
          const signedUrl = uploadRequest.data.signedUrl;
          const requestId = uploadRequest.data.requestId;

          await this.apiService.putFileToStorage(signedUrl, uploadFile).toPromise();

          const savedFile = await this.settingService.saveContentMedia(requestId).toPromise();

          resolve({default: savedFile.data});
        } catch (e) {
          this.toastService.error(`Lütfen sadece jpeg veya png formatında dosya yükleyiniz.`);
          reject('Lütfen sadece jpeg veya png formatında dosya yükleyiniz.');
        }


      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.onabort = function () {
        reject();
      };
      reader.readAsDataURL(file);
    });
  }

  abort() {
    if (this.reader) {
      this.reader.abort();
    }
  }
}
