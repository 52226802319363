import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  saveToken(token: string) {
    window.localStorage.setItem('jwtToken', token);
  }

  getToken(): string {
    return window.localStorage.jwtToken;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }
}
