<div class="col-sm-12 col-md-5">
  <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
    {{'table.records_shown_per_page' | translate:{
    'total': pagination.total,
    'from_records': (pagination.page - 1) * pagination.perPage + 1,
    'to_records': pagination.page * pagination.perPage
  } }}
  </div>
</div>
<!-- Pagination -->
<div class="col-sm-12 col-md-5">
  <div class="text-md-right float-md-end pagination-rounded">
    <pagination
      (pageChanged)="onPageChange($event)"
      [totalItems]="pagination.total" [maxSize]="3"
      [customPreviousTemplate]="prevTemplate"
      [customNextTemplate]="nextTemplate"
      [customFirstTemplate]="firstTemplate"
      [customLastTemplate]="lastTemplate"
      [boundaryLinks]="true" [itemsPerPage]="pagination.perPage">
    </pagination>
    <ng-template #prevTemplate><i class="fa fa-angle-left" aria-hidden="true"></i></ng-template>
    <ng-template #nextTemplate><span class="fa fa-angle-right"></span></ng-template>
    <ng-template #firstTemplate><i class="fa fa-angle-double-left" aria-hidden="true"></i></ng-template>
    <ng-template #lastTemplate><span class="fa fa-angle-double-right"></span></ng-template>
  </div>
</div>
