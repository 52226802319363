import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class UserPlanService {
  private apiPrefix = '/admin/user-plans/users/';

  constructor(
    private api: ApiService
  ) {
  }

  getUserWeeks(userId: number) {
    return this.api.get(`${this.apiPrefix}${userId}/weeks`);
  }

  getUserWeekItems(userId: number, weekId: number) {
    return this.api.get(`${this.apiPrefix}${userId}/weeks/${weekId}/items`);
  }
}
