import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copiable-text',
  templateUrl: './copiable-text.component.html',
  styleUrls: ['./copiable-text.component.scss']
})
export class CopiableTextComponent {

  @Input() label: string = '';
  @Input() text: string;

  copy() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
