import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class DocumentService {
  private readonly apiPrefix = '/admin/documents';

  constructor(
    private apiService: ApiService,
  ) {
  }

  createDocument(data: object) {
    return this.apiService.post(`${this.apiPrefix}`, data);
  }

  saveDocument(requestId: string) {
    return this.apiService.post(`${this.apiPrefix}/${requestId}`, {});
  }

  updateDocument(id: number, data: object) {
    return this.apiService.patch(`${this.apiPrefix}/${id}`, data);
  }

  getDocuments(queryString = '') {
    return this.apiService.get(`${this.apiPrefix}${queryString}`);
  }

  deleteDocument(id: number) {
    return this.apiService.delete(`${this.apiPrefix}/${id}`);
  }
}
