import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class LevelService {
  private readonly apiPrefix = '/admin/levels';

  constructor(
    private api: ApiService
  ) {
  }

  getLevels(): Observable<any> {
    return this.api.get(this.apiPrefix);
  }
}
