import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Adapter from "./ck-editor.adapter";
import { ApplicationSettingService } from "../../../core/services/application-setting/application-setting.service";
import { ApiService } from "../../../core/services/api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CkeditorComponent),
      multi: true
    }
  ]
})
export class CkeditorComponent implements ControlValueAccessor, OnInit {
  @Input() removeToolbarItems: string[] = [];
  ckEditor = ClassicEditor;
  ckEditorConfig;
  value?: string;

  constructor(
    private applicationSettingService: ApplicationSettingService,
    private api: ApiService,
    private toastService: ToastrService,
  ) {
  }

  ngOnInit() {

    if (this.removeToolbarItems.length > 0) {
      for (const removeToolbarItem of this.removeToolbarItems) {
        const index = this.ckEditor.defaultConfig.toolbar.items.findIndex(p => p == removeToolbarItem);
        if (index >= 0) {
          this.ckEditor.defaultConfig.toolbar.items.splice(index, 1);
        }
      }
    }

    this.ckEditorConfig = {
      extraPlugins: [this.customAdapterPlugin]
    };

  }

  customAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new Adapter(
        loader,
        editor.config,
        this.applicationSettingService,
        this.api,
        this.toastService,
      );
    };
  }

  onReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {

      return new Adapter(
        loader,
        editor.config,
        this.applicationSettingService,
        this.api,
        this.toastService,
      );

    };

  }

  onChange = (value: string | undefined | null) => {
  };

  ckEditChange(event: any) {
    const val = event.editor.getData();
    if (val) {
      this.onChange(val);
    }
  }

  onTouched = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
