import { Injectable } from "@angular/core";
import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { IRole } from "../../models/admin/role.model";
import { IAdmin } from "../../models/admin/admin.model";
import { EPermissionKeys } from "../../enums/permission-keys.enum";

type Actions = 'manage' | 'add' | 'edit' | 'view' | 'delete';
type Subjects =  EPermissionKeys;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

@Injectable()
export class AppAbilityService {

  constructor(private ability: Ability,) {
  }

  defineAbilitiesFor(role: IRole, admin: IAdmin) {
    const {can, rules, cannot} = new AbilityBuilder(AppAbility);

    for (const permission of role.permissions) {
      can('manage', permission.permissionKey);
    }
    return rules;
  }

  updateAbilities(role: IRole, admin: IAdmin) {
    const rules = this.defineAbilitiesFor(role, admin);
    this.ability.update(rules);
  }
}
