import { IAdmin } from "../../models/admin/admin.model";
import { Action, createReducer, on } from "@ngrx/store";
import * as AdminActions from './admin.actions';

export const adminFeatureKey = 'ADMIN_STATE_KEY';

export interface AdminState {
  admin: IAdmin
}

export const initialState: AdminState = {
  admin: null
};

export const reducer = createReducer(
  initialState,
  on(AdminActions.loadCurrentAdmin,
    (state: AdminState, {admin}) =>
      ({
        ...state,
        admin
      })
  )
);

export function adminReducer(state: AdminState | undefined, action: Action): any {
  return reducer(state, action);
}
