import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmedValidator } from "../../../core/validators/password-confirmed.validator";
import { SameFieldsValidator } from "../../../core/validators/same-fields.validator";
import { AuthService } from "../../../core/services/auth/auth.service";
import { finalize, Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy {
  fg: FormGroup;
  isSubmitted = false;
  isLoading = false;
  hasError = false;
  destroyed$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastrService,
    private translate: TranslateService,
    public modal: BsModalRef
  ) {
  }

  ngOnInit(): void {
    this.fg = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      currentPassword: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(6)]],
    }, {
      validators: [
        ConfirmedValidator('password', 'passwordConfirm'),
        SameFieldsValidator('currentPassword', 'password'),
      ]
    });
  }

  get f() {
    return this.fg.controls;
  }

  onSubmit(): void {
    this.isSubmitted = true;
    this.hasError = false;
    if (this.fg.invalid) {
      return;
    }

    this.isLoading = true;
    const data = this.fg.value;
    delete data['passwordConfirm'];
    this.authService.changePassword(data)
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.toastService.success(this.translate.instant('toast_messages.pass_update_success'));
        this.modal.hide();
      }, e => this.hasError = true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


}
