import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ExamScoreService {
  private apiPrefix = '/admin/exam-scores';

  constructor(
    private api: ApiService,
  ) {
  }

  getExamScores(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getExamScore(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  getLessons(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/lessons`);
  }

  getResultFile(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/result-file`);
  }

  getUserReports(userId: number, examId: number, qs = '') {
    return this.api.get(`${this.apiPrefix}/users/${userId}/exams/${examId}/average-scores${qs}`);
  }
}
