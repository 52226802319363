import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class PlanService {
  private readonly apiPrefix = '/admin/plans';

  constructor(
    private api: ApiService,
  ) {
  }

  getImportPlanGroupTemplate() {
    return this.api.get(
      `${this.apiPrefix}/excel-template`,
      new HttpParams(),
      'blob'
    );
  }

  importPlanGroupWithExcel(data) {
    return this.api.post(`${this.apiPrefix}/upload-excel`, data);
  }

  getAvailableGroup(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/available-plan-group`, data);
  }

  createPlan(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  getPlans(qs = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getPlanGroup(id: number, groupId: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}/groups/${groupId}`);
  }

  deletePlanGroup(planId: number, groupId: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${planId}/groups/${groupId}`);
  }

  deleteWeek(planId: number, groupId: number, weekId: number) {
    return this.api.delete(`${this.apiPrefix}/${planId}/groups/${groupId}/weeks/${weekId}`);
  }

  addGroupWeeks(planId: number, groupId: number, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/${planId}/groups/${groupId}/weeks`, data);
  }

  addGroupWeeksWithExcel(planId: number, groupId: number, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/${planId}/groups/${groupId}/weeks/upload-excel`, data)
  }

  updatePlanGroup(planId: number, groupId: number, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${planId}/groups/${groupId}`, data);
  }

  getRemoveEffects(planId: number, groupId: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${planId}/groups/${groupId}/remove-effects`)
  }
}
