import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class WorkbookService {
  private readonly apiPrefix = '/admin/workbooks';

  constructor(
    private api: ApiService,
  ) {
  }

  create(data) {
    return this.api.post(this.apiPrefix, data);
  }

  update(id, data) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  delete(id) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getList(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  importWithExcel(data) {
    return this.api.post(`${this.apiPrefix}/upload-excel`, data);
  }
}
