<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
<!--        <a routerLink="/" class="logo logo-dark">-->
<!--          <span class="logo-sm">-->
<!--            <img src="assets/images/logo.svg" alt="" height="22">-->
<!--          </span>-->
<!--          <span class="logo-lg">-->
<!--            <img src="assets/images/logo-dark.png" alt="" height="17">-->
<!--          </span>-->
<!--        </a>-->

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="32">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown1" dropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
             (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" dropdownToggle id="page-header-user-dropdown">
<!--          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">-->
          <span class="d-inline-block ms-1">{{currentAdmin.username}}</span>
          <i class="mdi mdi-chevron-down d-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item clickable" (click)="openEditProfileModal()">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'menu.topbar.my_profile' | translate}}
          </a>
          <a class="dropdown-item clickable" (click)="openChangePassModal()">
            <i class="bx bx-lock font-size-16 align-middle me-1"></i>
            {{ 'menu.topbar.change_password' | translate}}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger clickable" (click)="logout()">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'menu.topbar.logout' | translate}}
          </a>
        </div>
      </div>

    </div>
  </div>
</header>
