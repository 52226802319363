import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { LoaderComponent } from './loader/loader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PaginationComponent } from './pagination/pagination.component';
import { TranslateModule } from "@ngx-translate/core";
import { SearchInputComponent } from './search-input/search-input.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { CopiableTextComponent } from './copiable-text/copiable-text.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UserProfileBoxComponent } from './user-profile-box/user-profile-box.component';

@NgModule({
  declarations: [
    PagetitleComponent,
    LoaderComponent,
    SpinnerComponent,
    PaginationComponent,
    SearchInputComponent,
    DatetimePickerComponent,
    CopiableTextComponent,
    DatePickerComponent,
    CkeditorComponent,
    UserProfileBoxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule,
    TranslateModule,
    BsDatepickerModule,
    CKEditorModule,
  ],
  exports: [
    PagetitleComponent,
    LoaderComponent,
    SpinnerComponent,
    PaginationComponent,
    SearchInputComponent,
    DatetimePickerComponent,
    CopiableTextComponent,
    DatePickerComponent,
    CkeditorComponent,
    UserProfileBoxComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class UIModule {
}
