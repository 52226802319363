<div class="modal-header">
  <h5 class="modal-title mt-0">{{'menu.topbar.my_profile'|translate}}</h5>
  <button type="button" class="btn-close" (click)="modal.hide()">
    <span aria-hidden="true"></span>
  </button>
</div>

<div class="modal-body">
  <div class="row">

    <div class="col-12">
      <strong>{{'labels.email'|translate}}: </strong> {{currentAdmin.email}}
      <a class="clickable" (click)="openChangeMailModal()">({{'buttons.change'|translate}})</a>
    </div>

    <form [formGroup]="fg" (submit)="onSubmit()" class="col-12">

      <div class="row">
        <div class="col-md-6 col-12 mt-2">
          <label for="firstName" class="form-label">{{'labels.first_name'|translate}}</label>
          <input formControlName="firstName" id="firstName" class="form-control" autocomplete="off" type="text"
                 [placeholder]="'labels.first_name'|translate">
        </div>

        <div class="col-md-6 col-12 mt-2">
          <label for="lastName" class="form-label">{{'labels.last_name'|translate}}</label>
          <input formControlName="lastName" id="lastName" class="form-control" autocomplete="off" type="text"
                 [placeholder]="'labels.last_name'|translate">
        </div>

        <div class="col-12 mt-2">
          <label for="username" class="form-label">{{'labels.username'|translate}}</label>
          <input formControlName="username" id="username" class="form-control" autocomplete="off" type="text"
                 [placeholder]="'labels.username'|translate">
          <div *ngIf="isSubmitted && f.username.errors" class="invalid-feedback">
            <span *ngIf="f.username.errors.required">{{'validation.field_required'|translate}}</span>
            <span *ngIf="f.username.errors.minlength">
            {{'validation.min_length'|translate: {n: f.username.errors.minlength.requiredLength} }}
          </span>
          </div>
        </div>

        <div class="col-12 mt-2 text-end">
          <button [disabled]="isLoading" class="btn btn-primary" type="submit">
            <i *ngIf="isLoading" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            {{'buttons.save'|translate}}
          </button>
        </div>
      </div>


    </form>
  </div>
</div>
