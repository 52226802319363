import {FormGroup} from '@angular/forms';

export function SameFieldsValidator(controlName: string, matchingControlName: string) {

  return (formGroup: FormGroup) => {

    const control = formGroup.controls[controlName];

    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.sameFieldsValidator) {

      return;

    }

    if (control.value === matchingControl.value) {

      matchingControl.setErrors({sameFieldsValidator: true});

    } else {

      matchingControl.setErrors(null);

    }
  };
}
