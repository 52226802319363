<div class="modal-header">
  <h5 class="modal-title mt-0">{{'menu.topbar.change_password'|translate}}</h5>
  <button type="button" class="btn-close" (click)="modal.hide()">
    <span aria-hidden="true"></span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <form [formGroup]="fg" (submit)="onSubmit()">

      <div class="col-12 mt-2">
        <alert type="danger" *ngIf="hasError" [dismissible]="false">{{ 'validation.current_password_wrong'|translate }}</alert>
      </div>

      <div class="col-12 mt-2">
        <label for="currentPassword" class="form-label">{{'labels.current_password'|translate}}</label>
        <input formControlName="currentPassword" id="currentPassword" class="form-control" type="password"
               [placeholder]="'labels.current_password'|translate">
        <div *ngIf="isSubmitted && f.currentPassword.errors" class="invalid-feedback">
          <span *ngIf="f.currentPassword.errors.required">{{'validation.field_required'|translate}}</span>
          <span *ngIf="f.currentPassword.errors.minlength">
            {{'validation.min_length'|translate: {n: f.currentPassword.errors.minlength.requiredLength} }}
          </span>
        </div>
      </div>

      <div class="col-12 mt-2">
        <label for="password" class="form-label">{{'labels.new_password'|translate}}</label>
        <input id="password" formControlName="password" class="form-control" type="password"
               [placeholder]="'labels.new_password'|translate">
        <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
          <span *ngIf="f.password.errors.required">{{'validation.field_required'|translate}}</span>
          <span *ngIf="f.password.errors.minlength">
            {{'validation.min_length'|translate: {n: f.password.errors.minlength.requiredLength} }}
          </span>
          <span *ngIf="f.password.errors.sameFieldsValidator">{{'validation.passwords_cannot_same'|translate}}</span>
        </div>
      </div>

      <div class="col-12 mt-2">
        <label for="passwordConfirm" class="form-label">{{'labels.new_password_confirm'|translate}}</label>
        <input id="passwordConfirm" formControlName="passwordConfirm" class="form-control" type="password"
               [placeholder]="'labels.new_password_confirm'|translate">
        <div *ngIf="isSubmitted && f.passwordConfirm.errors" class="invalid-feedback">
          <span *ngIf="f.passwordConfirm.errors.required">{{'validation.field_required'|translate}}</span>
          <span *ngIf="f.passwordConfirm.errors.minlength">
            {{'validation.min_length'|translate: {n: f.passwordConfirm.errors.minlength.requiredLength} }}
          </span>
          <span *ngIf="f.passwordConfirm.errors.confirmedValidator">{{'validation.no_match_password'|translate}}</span>
        </div>
      </div>

      <div class="col-12 mt-2 text-end">
        <button [disabled]="isLoading" class="btn btn-primary" type="submit">
          <i *ngIf="isLoading" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          {{'buttons.save'|translate}}
        </button>
      </div>

    </form>
  </div>
</div>


