import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { finalize, Observable, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { AdminState } from "../../../core/ngrx/admin/admin.reducer";
import { selectAdmin } from "../../../core/ngrx/admin/admin.selectors";
import { IAdmin } from "../../../core/models/admin/admin.model";
import { ChangePasswordModalComponent } from "../change-password-modal/change-password-modal.component";
import { ChangeMailModalComponent } from "../change-mail-modal/change-mail-modal.component";
import { ProfileService } from "../../../core/services/admin-user/profile.service";
import { loadCurrentAdmin } from "../../../core/ngrx/admin/admin.actions";

@Component({
  selector: 'app-my-profile-modal',
  templateUrl: './my-profile-modal.component.html',
  styleUrls: ['./my-profile-modal.component.scss']
})
export class MyProfileModalComponent implements OnInit, OnDestroy {
  admin$: Observable<IAdmin>;
  currentAdmin: IAdmin;
  fg: FormGroup;
  isSubmitted = false;
  isLoading = false;
  destroyed$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private toastService: ToastrService,
    private store: Store<AdminState>,
    private modalService: BsModalService,
    private profileService: ProfileService,
    public modal: BsModalRef,
  ) {
    this.admin$ = store.select(selectAdmin);
  }

  ngOnInit(): void {
    this.admin$.pipe(takeUntil(this.destroyed$))
      .subscribe(adminData => {
        this.currentAdmin = adminData;
        this.setupForm();
      });
  }

  private setupForm(): void {
    this.fg = this.formBuilder.group({
      firstName: [this.currentAdmin.firstName ?? ''],
      lastName: [this.currentAdmin.lastName ?? ''],
      username: [this.currentAdmin.username, [Validators.required, Validators.minLength(3)]],
    })
  }

  openChangeMailModal(): void {
    const modalRef = this.modalService.show(ChangeMailModalComponent, {
      keyboard: false,
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',

    });
  }


  get f() {
    return this.fg.controls;
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.fg.invalid) {
      return;
    }

    this.isLoading = true;
    this.profileService.updateProfile(this.fg.value)
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.toastService.success(this.translate.instant('toast_messages.success_updated'));
        const admin: IAdmin = {...this.currentAdmin, ...r.data};
        this.store.dispatch(loadCurrentAdmin(admin));
        this.modal.hide();
      }, err => {
        this.toastService.error(this.translate.instant('toast_messages.enter_valid_values'));
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
