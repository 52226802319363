import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITablePagination } from "../../../core/interfaces/table-pagination.interface";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() pagination: ITablePagination;
  @Output() pageChanged = new EventEmitter();

  onPageChange(event) {
    this.pageChanged.emit(event.page);
  }

}
