import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class VideoCommentService {
  private apiPrefix = '/admin/video-comments';

  constructor(
    private api: ApiService
  ) {
  }

  getComments(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  pinComment(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/pin`, {});
  }

  unpinComment(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/unpin`, {});
  }

  deleteComment(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }
}
